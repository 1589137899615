<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
        justify-center
        wrap
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="'TherapyPlanActions'"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Therapy Plan Actions'"
                    :user_id="user_id"
                    :user_type="user_type"
                    :is-custom-filter="false"
                    :pre-selected-filters="filters.selectedFilters"
                    :isDetail="false"
                    :isExport="true"
        >
          <template slot="filters">
            <date-range-filter :filter-id="'voucher_activation_filter'"
                               :place-holder="'Activation Date'"
                               :query-type="'Date'"
                               :column="'activation_date'"
                               :operator="'between'"
            >
            </date-range-filter>
            <filters :filters="filters.therapist_filters"></filters>
            <filters :filters="filters.client_outstanding_filters"></filters>
            <filters :filters="filters.therapist_outstanding_filters"></filters>
            <filters :filters="filters.key_milestone_filters"></filters>
            <filters :filters="filters.additional_session_request_filters"></filters>
            <filters :filters="filters.therapy_status_filters"></filters>
            <multi-select-filters :filter-id="'plan_actions_therapy_organization_filters'"
                                  :filters="filters.therapy_organization_filters"
                                  :placeholder="'Select organization'"
                                  :column="'id'"
                                  :query-type="'relationship'"
                                  :operator="'in'"
                                  :relation-ship-name="'voucherCode.org'"
            ></multi-select-filters>
            <!-- Filter bookings according to these plans-->
            <template v-if="filters.plan_filters.length > 0">
              <multi-select-filters :filter-id="'plan_filter'"
                                    class="ml-2"
                                    :filters="filters.plan_filters"
                                    :placeholder="'Select Plan'"
                                    :column="'id'"
                                    :query-type="'relationship'"
                                    :relation-ship-name="'voucherCode.plan'"
              ></multi-select-filters>
            </template>
          </template>
        </data-table>
      </template>

      <!-- Plan Detail -->
      <plan-detail v-if="planDetailDialog"
                   :visible="planDetailDialog"
                   :rowData="selectedPlan"
                   :user_id="user_id"
                   v-on:hide="hideDetailModal"
                   v-on:get-therapy-plan="getTherapyPlans"
      >
      </plan-detail>
    </v-layout>
  </v-container>
</template>

<script>
import pageUrlHelper from "@/helpers/pageUrlHelper";
import DataTable from "@/components/common/dataTable/DataTable";
import Filters from "@/components/common/dataTable/filters/Filters";
import TherapyPlanDetail from "@/components/main/therapyPlanAction/TherapyPlanDetail";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import PlanDetail from "@/components/main/planDetails/PlanDetail";

export default {
  name: "TherapyPlanActions",
  components: {
    DataTable,
    Filters,
    TherapyPlanDetail,
    MultiSelectFilters,
    DateRangeFilter,
    PlanDetail
  },
  props: {
    user_id: {
      user_id: [Number, String]
    },
    user_type: {
      type: String
    },
    header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiurl: 'therapy_plan_actions',
      search: {
        placeholder: 'Search',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'created_at',
          sortField: 'created_at',
          direction: 'desc'
        }
      ],
      filters: [],
      selected_filters: [],
      loaded: false,
      selectedPlan: null,
      planDetailDialog: false,
      selectedTab: 0,
      therapyPlanUrl: "client/therapy-plans",
      therapyPlans: {
        fields: [],
        data: []
      },
    }
  },
  created() {
    this.fetchFilters();
    this.filterSearchedRecord();
  },
  methods: {
    fetchFilters() {
      this.$http.get(this.url + 'filters/therapy_plan_actions').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.loaded = true;
        }
      });
    },
    removeSortFromUrl(key, sourceURL) {
      let rtn = sourceURL.split("?")[0],
          param,
          params_arr = [],
          queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
    },
    hideDetailModal() {
      this.selectedPlan = null;
      this.planDetailDialog = false;
    },
    filterSearchedRecord() {
      const search = pageUrlHelper.getParamValue('search');
      if (search) {
        this.search.data = search;
      }
    },
    getTherapyPlans() {
      this.$http.get(this.url + this.therapyPlanUrl, {
        params: {
          user_id: this.user_id,
          timezone: this.timezone,
        }
      }).then(response => {
        this.therapyPlans.fields = response.data.therapy_plans.fields;
        if (response.data.therapy_plans.data.length > 0) {
          this.therapyPlans.data.push(...response.data.therapy_plans.data);
        }
        this.areTherapyPlanLoaded = true;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "Error")
      })
    },
  },
  events: {
    'open-record': function (data, tab) {
      this.selectedPlan = data;
      this.selectedTab = tab;
      this.planDetailDialog = true;
    },
  },
}
</script>
